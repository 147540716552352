<template>
  <div>
    <Section1 :keyWord="'La alternativa a empeñar coche de forma fácil, segura y Online'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>La alternativa a empeñar coche de forma fácil, segura y Online</strong></h2>
      <p><strong>Empeñar coche sin entregarlo</strong> es uno de los cebos que utilizan algunas empresas para atraer clientes que necesitan liquidez urgente. A simple vista parece una opción atractiva. Sin embargo, cuando comienzas con los trámites, te puedes encontrar con condiciones abusivas como el cambio de titularidad del vehículo o el cobro de un alquiler o renting por su uso.</p>
      <p>¿Sabías que existe una alternativa en la que tu coche solo actúa como aval de un crédito y que no implica ningún tipo de restricción por su uso? Descubre cómo conseguir liquidez en cuestión de horas, completando la solicitud de manera 100% online y con ventajas para clientes como: un gestor personal a tu disposición o la revisión anual gratuita para tu coche. Te presentamos el crédito con aval de coche de Ibancar.</p>

      <h3 class="naranja"><strong>¿Necesitas liquidez de forma urgente para resolver un problema inesperado?</strong></h3>
      <p>Te presentamos la alternativa a <strong>empeñar tu coche sin entregarlo</strong> con Ibancar. Ibancar, es una entidad de crédito con 8 años de experiencia en el sector y más de 4000 clientes en su base de datos.</p>
      <p>A diferencia de las entidades de crédito tradicionales, en Ibancar hemos desarrollado soluciones alternativas con condiciones flexibles para la obtención de liquidez. Además, hemos desarrollado una metodología ágil y segura que te permite completar el proceso de manera 100% online y en cuestión de minutos para poder contar con el dinero en tu cuenta en unas pocas horas. </p>

      <h3 class="naranja"><strong>Alternativa al empeño de tu coche y seguir usándolo sin ningún tipo de restricciones?</strong></h3>
      <p>Entidades bancarias y servicios de empeño, ofrecen titulares atractivos en sus anuncios para atraer a las personas que se encuentran en una situación crítica. Una vez se empieza el trámite, muchas personas se encuentran con períodos de aprobación interminables, y cláusulas abusivas como: cambiar la titularidad de tu vehículo, no utilizar tu coche hasta que satisfagas la deuda o que tengas que pagar cada vez que quieras utilizarlo.</p>
      <p>En Ibancar te ofrecemos la alternativa; un servicio personalizado, sin cláusulas abusivas ni esperas interminables.</p>

      <h3 class="naranja"><strong>Requisitos para acceder al crédito con aval de coche en Ibancar</strong></h3>
      <p>Sólo necesitas rellenar la solicitud a través del formulario de contacto en nuestra web.</p>

      <p>Recibirás una propuesta de crédito personalizada y luego deberás presentar la siguiente documentación personal:</p>
      <ul>
          <li>Fotografía de tu DNI, ambas caras en fondo blanco.</li>
          <li>Última nómina, pensión o autónomo.</li>
          <li>Foto Selfie con DNI.</li>
      </ul>

      <p>Además, también necesitaremos que nos envíes la siguiente documentación del vehículo:</p>
      <ul>
          <li>Permiso de circulación y ficha técnica.</li>
          <li>1 Foto del cuentakilómetros con DNI.</li>
          <li>4 fotos del coche y 1 video del coche.</li>
      </ul>

      <p>Toda esta información la podrás encontrar detalladamente abajo en el apartado de preguntas comunes. En este apartado, también encontrarás información acerca de las condiciones en las que debe estar el vehículo para poder solicitar el crédito con aval de coche de Ibancar.</p>

      <p>Una vez enviada la documentación requerida, se activará tu préstamo para que puedas disponer de tu dinero cuanto antes. Si tienes alguna duda acerca del proceso para empeñar tu coche en Ibancar, puedes contactar con nuestro equipo de asistencia y un asesor te ayudará a completar cada paso.</p>

      <h3 class="naranja"><strong>El crédito online más ágil y flexible del mercado</strong></h3>
      <p>Puede que te haya llegado algún gasto que no esperabas, o bien que te hayas decidido a emprender el negocio de tus sueños y necesitas una liquidez que a día de hoy no tienes. ¡En Ibancar te podemos ayudar! </p>
      <p>La alternativa al empeño de coche que te ofrecemos es ágil, flexible y 100% online. En el momento que envías la solicitud, se te asigna un asesor que te acompañará a lo largo de todo el trámite. Minutos después de enviar la solicitud, recibirás una propuesta de préstamo pre aprobada. </p>
      <p>En el punto anterior te hemos comentado que documentación se debe de aportar, si la tienes identificada y puedes entregarla justo después de la propuesta pre aprobada ¡estás a unas horas de tener la cantidad en tu cuenta! Una vez la documentación presentada es aprobada por nuestros agentes, todo se activa para que puedas recibir el dinero en un máximo de 24 horas. </p>

      <h3 class="naranja"><strong>¿Tienes todavía alguna duda? ¡No te preocupes, resolvemos tus dudas! </strong></h3>
      <p>¿Todavía no te has decidido a obtener la liquidez que necesitas bajo el sistema de crédito con aval de coche de Ibancar? No te preocupes, estamos aquí para ayudarte y resolver todas tus dudas para que puedas tomar una decisión.</p>
      <p>Desde el momento que inicias el proceso se te asigna un gestor personal que será tu guía para agilizar el trámite. Por supuesto estará preparado para responder cualquier pregunta e incluso para asesorarte sobre cuál es la mejor opción para tu perfil. </p>
      <p>Así que no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudar a todos nuestros clientes, que ascienden a más de 4500 tras 8 años de actividad en el sector.</p>

      <h3 class="naranja"><strong>¿Esta alternativa está disponible en toda España?</strong></h3>
      <p>Por supuesto. Puedes comenzar con tu solicitud desde cualquier ubicación de la península o las Islas Baleares de manera online y recibir el dinero en tu cuenta en cuestión de horas.</p>

      <h3 class="naranja"><strong>¿Tengo opción de pedir el crédito con aval de coche de Ibancar si estoy en una lista de morosos?</strong></h3>
      <p>Claro que sí. En Ibancar no rechazamos solicitudes de crédito por el simple hecho de que los clientes se encuentren en un fichero de impagados.</p>
      <p>Gracias a esta flexibilidad del crédito con aval de coche de Ibancar no tienes que comprometer a nadie para que actúe en forma de aval, no recibirás un no por el simple hecho de estar en una lista de impagados en la que muchas veces ni siquiera sabías que te habían incluido y, sobre todo, te convertirás en un cliente de nuestra empresa con beneficios exclusivos en lugar de colocarte en una posición de deudor al que se le presiona de manera constante con aumentos de intereses y penalizaciones.</p>
      <p>Ibancar te ofrece la posibilidad de recibir un crédito a tu medida incluso si te han incluido en la lista ASNEF. Puedes contactar con nosotros y uno de nuestros asesores te ayudará a realizar tu solicitud y encontrar la mejor solución para ti.</p>
      <p>Esta es una ventaja única en el mercado de créditos ágiles, por lo que el crédito con aval de coche coche en Ibancar es probablemente la manera más sencilla y segura de conseguir un crédito en tu cuenta en cuestión de horas. ¡No lo pienses más y comienza tu solicitud ahora mismo!</p>

      <h3 class="naranja"><strong>¿Qué pasa si no llego a una cuota?</strong></h3>
      <p>El crédito de Ibancar se ajusta a las características de tu situación personal. Durante el proceso de solicitud contarás con asesoramiento personalizado para que nunca te veas en la situación de no poder satisfacer el pago de tus cuotas.</p>
      <p>Trabajamos siempre para ofrecerte la mejor solución financiera en función de tu situación personal. Así que no te preocupes, recibirás el mejor servicio con nosotros, y el crédito se podrá ajustar según tu nivel de riesgo durante la solicitud para que los pagos sean siempre cómodos para ti.</p>
      <p>Vas a tener flexibilidad para afrontar los pagos. Por ejemplo:</p>
      <ul>
        <li>Te dejamos escoger el día del mes en el que abonar las cuotas.</li>
        <li>Si resulta que en algún mes te ha surgido un imprevisto y no puedes hacer frente al pago en la fecha acordada, en Ibancar te concedemos hasta 5 días de cortesía para abonar el importe.</li>
      </ul>
      <p>Todo este proceso además de ser seguro, es muy ágil. Puedes tener el dinero en tu cuenta en horas sin necesidad de desplazarte a ningún sitio.</p>
      <p>Ahora que ya sabes cómo funciona, ¿a qué esperas para solicitar el servicio de Ibancar? ¡Empieza hoy mismo! No pierdas la oportunidad de conseguir el dinero que necesitas de forma rápida, fácil y segura.</p>
     
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../empenar-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coche sin entregarlo</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Empeña tu Coche sin dejar de conducirlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../empenar-coche-malaga" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Málaga</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
           <div class="card m-4">
            <a href="../empenar-coche-murcia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Murcia</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
      </div>
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
           <div class="card m-4">
            <a href="../empenar-coche-tenerife" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Tenerife</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
           <div class="card m-4">
            <a href="../empenar-coche-barcelona" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Barcelona</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
           <div class="card m-4">
            <a href="../empenar-coche-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Sevilla</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
      </div>
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
           <div class="card m-4">
            <a href="../empenar-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Las Palmas</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
           <div class="card m-4">
            <a href="../empenar-coche-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Madrid</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../empenar-coche-girona" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches Girona</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
      </div>
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
            <a href="../empenar-coche-mas-diez-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Empeño de coches con mas de diez años</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Antes de empeñar tu coche, prueba el préstamo con aval coche</p>
              </div>
            </a>
          </div>
      </div>
      
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'EmpenarCoche',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Empeña tu coche y obtén el dinero en menos de 24 horas, 100% online y sin cambio de titular',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Empeñar coche para conseguir dinero rápido en tu cuenta nunca había sido tan sencillo. Gracias al préstamo con aval de coche de Ibancar podrás utilizar tu vehículo como garantia de pago para tu crédito y seguir utilizándolo en las mismas condiciones que hasta ahora.'},
        {name:'keywords', content:'empeñar coche'}
      ],
      link:[
        {href: 'https://ibancar.com/empenar-coche', rel:'alternate', hreflang:'es-es'},
        {href: 'https://ibancar.mx/empeno-de-autos-en-ibancar', rel:'alternate', hreflang:'es-MX'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>